@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

html,
body {
  overflow-x: hidden !important;
  width: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 0 !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

a {
  text-decoration: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

input {
  outline: none !important;
  box-shadow: none !important;
}

figure {
  margin-bottom: 0;
}

.footer {
  background: #111;
}

.foot-logo-main {
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
}

.foot-logo-main img {
  width: 100%;

}

.footer-links ul {
  justify-content: center;
  gap: 40px;
}

.footer-links {
  padding-top: 37px;
  padding-bottom: 50px;
}

.footer-links ul li a {
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.logo-foot {
  padding-top: 85px;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.377);
  padding-top: 23px;
  padding-bottom: 23px;
}

.footer-bottom p {
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px;
  text-align: center;
}

.header-bg-color {
  background: linear-gradient(90deg, #01DE5D 11.87%, #015F92 100%);
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.banner-main .header-bg-color {
  background: transparent
}

.nav-heading a {
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.nav-heading {
  gap: 40px;
  align-items: center;
}

.header-search.form-control {
  border-radius: 45px;
  background: #FFF;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px 26px;
  width: 359px;

}

i.search {
  position: absolute;
  right: 21px;
  top: 13px;
  color: #0178B9;
  font-size: 22px;
}

.search-form {
  position: relative;
}

.banner-main {
  /* background-color: #018CC9; */

}

.inner-signup-wrapper {
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
}

.inner-bannersec h1 {
  color: #FFF;
  font-family: Oswald;
  font-size: 130px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.inner-bannersec h5 {
  color: #FFF;
  font-family: Oswald;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.inner-bannersec p {
  color: #FFF;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  /* 143.75% */
  max-width: 700px;
  padding-bottom: 35px;
}

.banner-btn.btn-primary {
  border-radius: 50px;
  background: #0751B5;
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 22px 39px;
  border: 1px solid #0751B5;
}

.banner-btn.btn-primary:hover {
  background-color: transparent;
  color: white;
  border-color: #FFFFFF;
}

.inner-bannersec {
  padding-bottom: 257px;
  padding-top: 266px;
}

.banner-main {
  width: 100%;
}

.inner-banner-main {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.banner-main .header-bg-color {
  background-color: transparent;
}

.arrows-bann {
  position: absolute;
}

.banner-main {
  position: relative;
}

.our-price h5 {
  color: #111;
  font-family: Oswald;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: #111111;
  padding-bottom: 30px;
}

.our-price p {
  color: #333;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.our-price {
  padding-top: 70px;
}

.pricing-plan-sec {
  padding-top: 82px;
  padding-bottom: 82px;
  /* background-image: url(./Assets/images/girls-ride-horses\ 2.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(https://www.myequisource.com/api/wp-content/uploads/2024/04/image0-2.png) no-repeat center/cover !important */
}

/* .inner-pricing-plan {
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
} */

.save-more {
  border-radius: 20.214px;
  background: #018CC9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-right: 12px;

}

.tabs-main-update .tab-content {
  height: 100%;
}

.tabs-main-update .tab-content>.active {
  height: 100%;
}

.save-more-head {
  color: #FFF;
  font-family: Inter;
  font-size: 32.343px;
  font-style: normal;
  font-weight: 400;
  line-height: 48.514px;
  /* 150% */
}

.save-more h5 {
  color: #FFF;
  font-family: Inter;
  font-size: 32.343px;
  font-style: normal;
  font-weight: 700;
  line-height: 48.514px;
  padding-bottom: 24.5px;
}

.upper-main-header .header-bg-color {
  background: transparent;
  position: absolute;
  z-index: 999;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;

}

.text-save-more {
  color: #FFF;
  font-family: Inter;
  font-size: 18.193px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.257px;
  /* 133.333% */
}

.inner-save-texts {
  padding: 70px 19px 53px 40px;
}

.img-horse figure img {
  width: 100%;
}

.our-suscrib {
  border-radius: 20.214px;
  border: 1px solid #E2E2E2;
  background: #FFF;
  padding: 42px 40px;
  height: 100%;
  margin-left: 12px;
}

.our-suscrib h5 {
  color: #0B0914;
  font-family: Inter;
  font-size: 32.343px;
  font-style: normal;
  font-weight: 600;
  line-height: 40.429px;
  /* 125% */
  padding-bottom: 52px;
}

.our-features {
  border-radius: 20.214px;
  border: 1px solid #E2E2E2;
  background: #FFF;
  padding: 32px;
  height: 100%;
  margin-left: 12px;
}

.our-features-heading {
  color: #111;
  text-align: center;
  font-family: Oswald;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.dollar-price {
  color: #0B0914;
  font-family: Inter;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 40.429px;
  /* 80.857% */
  padding-bottom: 8px;
}

.heading-list-dollar {
  color: #111;
  font-family: Inter;
  font-size: 22.236px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 24.26px;
}

.list-dollar li {
  color: #333;
  font-family: Inter;
  font-size: 18.193px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 21.26px;
  position: relative;
  padding-left: 30px;
}

.list-dollar li:last-child {
  padding-bottom: 53px;
}

.inner-ul-li {
  border-bottom: 1px dashed #A9A9AA;
}

.node-head {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 4px;
  padding-top: 22.83px;
}

.main-table-home table tr td:hover {
  background-color: #e1edf4;
  color: #000;
}

.main-table-home table tr td.active {
  background-color: #b9e5fb;
  color: #000;
}

.text-note {
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.grade-sec h5 {
  color: #111;
  font-family: Oswald;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 50px;
}

.grade-sec {
  padding-top: 101px;
}

.index-value {
  padding-top: 30px;
  padding-bottom: 16px;
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* ul.nav-tabs.main-tabs-home {
  gap: 28px;
  flex-wrap: nowrap;
  border: 0;
  margin-bottom: 24px;
}

ul.nav-tabs.main-tabs-home li {
  width: 100%;
}

ul.nav-tabs.main-tabs-home li button {
  width: 100%;
  padding: 25px 0px;
  color: #111;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid #D0D0D0;
  background: #F4F4F4;
  border-radius: 0 !important;
}

.main-tabs-home .nav-link.active {
  background-color: #0281C0 !important;
  color: #FFF !important;
}

ul.nav-tabs.main-tabs-home li button:hover {
  background-color: #0281C0 !important;
  color: #FFF !important;
} */

.main-table-home table tr th {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 23px 0;
}

.bg1 {
  background: #01E05B;
}

.bg2 {
  background: #01CB77;
}

.bg3 {
  background: #01B692;
}

.bg4 {
  background: #01A1AE;
}

.bg5 {
  background: #018CC9;
}

.main-table-home table tr td {
  padding: 17px 0 17px 0;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #F9F9F9;
  color: #111;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.main-table-home table {
  table-layout: fixed;
}

.charts-upper {
  /* border-radius: 14px; */
  border: 1px solid #E2E2E2;
  background: #FFF;
  padding: 50px 112px 50px 18px;
  margin-top: 30px;
}

.inner-score {
  border: 8px solid #01D657;
  width: 94.80px;
  height: 94.80px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.inner-score p {
  color: #111;
  text-align: center;
  font-family: Inter;
  font-size: 32.889px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.index-sco-heading {
  text-align: center;
}

.index-sco-heading p {
  color: #111;
  text-align: center;
  font-family: Inter;
  font-size: 19.185px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 12px;
  padding-bottom: 20.5px;
}

.chart-btn {
  border-radius: 50px;
  background: #0751B5;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 17.5px 33.5px;
  position: relative;
  border: 1px solid #0751B5;
  display: inline-block;
}

.chart-btn:hover {
  border-color: #01D657;
  background-color: transparent;
  color: #01D657;
}

.index-score-upper::after {
  content: "";
  border: 1px solid #00000012;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.index-score-upper {
  position: relative;
}

.chart-upper-btn {
  text-align: center;
}

.tick-svg {
  position: absolute;
  left: 0px;
  top: 0;
}


.willson {
  color: #111;
  text-align: center;
  font-family: Oswald;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 45px;
}

.upper-login-img {

  background-image: url(./Assets/images/Frame\ 17.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: revert-layer;

}

.signup-upper {
  /* background-image: url(./Assets/images/girls-ride-horses\ 2.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.inner-sgnup {
  border-radius: 20px;
  background: rgba(248, 248, 248, 0.70);
  box-shadow: 0px 0px 26.2px 9px rgba(0, 0, 0, 0.15);
  padding: 46px 90px;

  max-width: 600px;
  margin: auto;
}

.inner-sgnup.custm-sign-in {
  padding: 46px 90px 76px 90px;
}

.inner-sgnup.custm-reset-upper {
  padding: 46px 90px 98px 90px;
}

.inner-sgnup h5 {
  color: #1C1C1C;
  font-family: Oswald;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-bottom: 2px;
}

.inner-sgnup .alredy-signup {
  color: #1C1C1C;
  font-family: Inter;
  font-size: 12.924px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 24px;
  text-align: center;
}

.inner-sgnup .alredy-signup a {
  color: #018CC9;
  font-family: Inter;
  font-size: 12.924px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input-signup-main label {
  color: #666;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10.97px;
}

.input-signup-main {
  margin-bottom: 24px;
}

.input-signup-main .form-control {
  color: black;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 14px;
  border-radius: 11px;
  border: 1px solid #9f9f9f87;
  background: rgba(248, 248, 248, 0.70);

}

.input-signup-main .form-control::placeholder {
  color: #9F9F9F;

}

.upper-signup-clas {
  padding-top: 83px;
  padding-bottom: 73px;
}

.btn-create-acc.btn-primary {
  border-radius: 67px;
  background: #018CC9;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
  padding: 16px;
  border: 1px solid #018CC9;
}

.btn-create-acc.btn-primary:hover {
  background-color: transparent;
  color: #018CC9;
  border: 1px solid #018CC9;
}

.btn-create-acc-loader.btn-primary {
  border-radius: 67px;
  background: #018CC9;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
  padding: 16px;
  border: 1px solid #018CC9;
}

.btn-create-acc-loader.btn-primary:hover {
  background-color: #018CC9;
  color: #018CC9;
  border: 1px solid #018CC9;
}

.have-trou {
  color: #018CC9;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  width: 100%;
}

.remember-me input {
  width: 25px;
  height: 25px;
}

.remember-me .form-check {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.remember-me {
  margin-bottom: 20px;
}

.trouble-name {
  margin-bottom: 37px;
}

.reset-pass {
  margin-bottom: 34px;
}

.reset-pass-p {
  color: #666;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 26px;
}

.upper-reset-clas {
  padding-top: 103px;
  padding-bottom: 103px;
}

.upper-fotgot-clas {
  padding-top: 189px;
  padding-bottom: 189px;
}

.sign-register {
  border-radius: 45px;
  background: #FFF;
  color: #018CC9 !important;
  font-family: Oswald !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 5px;
  width: 230px;
  gap: 6px;
  border: 1px solid white;
}

.sign-register i {
  font-size: 20px;
}

.sign-register:hover {
  color: white !important;
  background-color: transparent;
}



.upper-slider-home .slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  content: "";
  text-align: center;
  opacity: 1;
  color: #000;
  background-color: #ffffff3d;

}

.upper-slider-home .slick-dots ul {
  display: flex;
  gap: 49px;
  margin-left: 15px;
}

/* .upper-slider-home .slick-dots li.slick-active button:before{
  opacity: 1;
  background: linear-gradient(to right, white 50%, #ffffff3d 50%) right;
  background-size: 200% 200%;
  background-position: left bottom;
  transition: all 2s ease-out;
  background-repeat: no-repeat;
  animation: gradientAnimation 2s ease-out ;
} */
.upper-slider-home .slick-dots li.slick-active button:after {
  content: "";
  opacity: 1;
  background-color: #fff;
  transition: all 2s ease-out;
  background-repeat: no-repeat;
  animation: gradientAnimation 2s ease-out forwards;
  width: 0%;
  height: 3px;
  height: 3px;
  left: 0;
  top: 0;
  position: absolute;
}


.upper-slider-home .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 100%;
  height: 3px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.will-head {
  color: #111;
  text-align: center;
  font-family: Oswald;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 45px;
}


.slider-images figure {
  position: relative;
  padding-bottom: 54%;
}

.slider-images figure img {
  position: absolute;
  border-radius: 8px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-logo {
  width: 220px;
  margin-bottom: 0;
}

.header-logo img {
  width: 100%;
}

.upper-chart-main {
  padding-left: 60px;
  height: 100%;
  display: flex;
  align-items: end;
}

.sec-same-img figure {
  position: relative;
  padding-bottom: 93%;
  margin-bottom: 0px;
  margin-right: 9px;
  margin-left: 9px;
}

.sec-same-img figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}


.upper-slider-home .slick-dots li {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 100%;
  margin: 0 !important;
  padding: 0;
  cursor: pointer;

}

.upper-slider-home .slick-dots li span {
  color: #FFF;
  font-family: Oswald;
  font-size: 26.514px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.upper-slider-home .slick-dots {
  bottom: 80px;
  max-width: 1310px;
  margin: 0 auto;
  width: 100%;
  left: 0;
  right: 0;
  text-align: left;
}

.upper-slider-home .slick-dots ul {
  text-align: left;
}

.upper-update-acc .header-bg-color {
  background: transparent;

}

.upper-update-acc {
  background-image: url(./Assets/images/updat.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.75);
}

.update-acc-page-banner h5 {
  color: #FFF;
  font-family: Oswald;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 163px;
  text-align: center;
  margin-bottom: 0;
  padding-top: 108px;
}

.inner-upper-acc {
  background: rgba(0, 0, 0, 0.75);

}

.tabs-main-update {
  padding-top: 120px;
  padding-bottom: 101px;
}

.my-acc-mian {
  color: #111;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding-top: 41px;
  padding-bottom: 41px;
  margin-bottom: 0;
  padding-left: 41px;
}


.tabs-main-update .inner-pill-inner {
  border-radius: 10px;
  background: #FAFAFA;
  overflow: hidden;
}

.tabs-main-update .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #018CC9 !important;
  color: #FFF;

}

.tabs-main-update .nav-link {
  display: flex !important;
  align-items: center;
  gap: 10px;
  padding: 15px 41px;
  border-radius: 0 !important;
  color: #111;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.tabs-main-update .nav-link.active svg {
  filter: invert(1);
}

.upper-right-sec-update {
  border-radius: 5px;
  background: #FAFAFA;
  padding: 38px 83px;
  height: 100%;
}

.change-pass-main {
  padding-bottom: 39px;
  color: #111;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.input-account-comn label {
  color: #111;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.input-account-comn input {
  border-radius: 70px;
  border: 1.5px solid #DCDCDC;
  background: #FFF !important;
  color: black;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 18px 30px;
}

.input-account-comn input::placeholder {
  color: rgba(17, 17, 17, 0.60);
}

.input-account-comn {
  margin-bottom: 25px;
}

.input-account-comn.marg-custm {
  margin-bottom: 32px;
}

.change-pass-btn-main.btn-primary {
  border-radius: 50px;
  background: #018CC9;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  padding: 18px 25px;
  border: 1px solid #018CC9;
  margin-top: 17px;
}

.change-pass-btn-main.btn-primary:hover {
  background-color: transparent;
  border: 1px solid #018CC9;
  color: #018CC9;

}

.change-pass-btn-main.btn-primary:active {
  background-color: transparent !important;
  border: 1px solid #018CC9 !important;
  color: #018CC9 !important;
}

.dont-have {
  color: #111;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 35px;
}

.grades-table {
  display: flex;
  flex-wrap: unset !important;
  gap: 10px;
  margin-bottom: 24px !important;
}

.grades-table .form-select {
  width: auto;
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  min-width: 335px;
  border: 1.5px solid #D9D9D9;
  margin-right: 15px;
}

.grades-table .nav-item a {
  width: 100%;
  padding: 24px 0px;
  color: #111;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid #D0D0D0;
  background: #F4F4F4;
  border-radius: 0 !important;
  display: block;
}

.grades-table .nav-item {
  width: 100%;

}

.grades-table .nav-item a.active {
  background-color: #0281C0 !important;
  color: #FFF !important;
}

.form-select:focus {
  box-shadow: none !important;
  border: 1px solid #015F92 !important;
}

.sinc-clas {
  color: #A0A0A0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 162.5% */
  margin-bottom: 8px;
}

.heading-strike {
  color: var(--Black, #121212);
  font-family: Oswald;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}

.para-detail-page {
  color: var(--Secondary, #616161);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 162.5% */
  margin-bottom: 20px;
}

.standind-clas span {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.standind-clas {
  color: #616161;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inner-sec-border {
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
}

.enter-fees {
  color: var(--Black, #121212);
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}

.enter-fees span {
  color: #018CC9;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.accordion-detaipage .accordion-button.collapsed::after {
  background-image: none !important;
  content: "+" !important;
  font-size: 35px;
  height: 41px !IMPORTANT;
  background-color: #01c66757;
  width: 41px !important;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: normal;
  transform: rotate(90deg) !important;
  line-height: 39px !important;
}

.accordion-detaipage .accordion-button::after {
  background-image: none !important;
  content: "-" !important;
  font-size: 35px;
  height: 41px !IMPORTANT;
  background-color: #01c66757;
  width: 41px !important;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: normal;
  line-height: 30px !important;
}

.accordion-detaipage .accordion-button {
  border-radius: 16px !important;
  background: var(--Neutral-100, #FFF);
  color: #121212 !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Oswald;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.accordion-detaipage .accordion-item {
  border-bottom: 0 !important;
  margin-bottom: 32px;
}

.accordion-detaipage .accordion-body {
  box-shadow: 0px 3px 17px -12px rgba(0, 0, 0, 0.75);

}

.accordion-detaipage .accordion-button:not(.collapsed) {
  background-color: white !important;
  box-shadow: 0px 3px 17px -12px rgba(0, 0, 0, 0.75) !important;
}

.accordion-detaipage .accordion-header {
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}

.row-slider-accordion-wrapper {
  padding-top: 64px;
  padding-bottom: 72px;
}

.leading-para h5 {
  color: #111;
  font-family: Oswald;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 26px;
}

.leading-para p {
  color: var(--Secondary, #616161);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
}

.leading-para {
  padding-bottom: 101px;
}

.sire-table-clas h5 {
  color: #111;
  font-family: Oswald;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 50px;

}

.siretable {
  padding-bottom: 40px;
}

.upper-detail-button {
  padding-bottom: 70px;
}

.prfile-img-show figure {
  width: 90px;
  height: 90px;
  margin-bottom: 27px;
  border-radius: 100%;
  overflow: hidden;
}

.prfile-img-show figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.header-profie {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 0;
}

.header-profie img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.verified-name {
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 5px;
}

.verified-user {
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
}

.profile-img-header {
  align-items: center;
  gap: 11px;
}

.custom-arrow {
  position: absolute;
  top: 39%;
}

.custom-arrow.custom-next-arrow {
  right: -20px;
}

.custom-arrow.custom-prev-arrow {
  left: -20px;
}

.custom-arrow i {
  font-size: 23px;
}

.custom-arrow i::before {
  font-size: 25px;
}

.slider-detailpage {
  padding-right: 5px;
}

.innnerright-sec {
  padding-left: 10px;
}

.remember-me label {
  color: #666;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 4px;
}

.main-table-home table tr td.empty-space {
  padding: 0;
  padding-bottom: 10px;
  border: 0;
  background: transparent;
}


.tabl2sec {
  background-color: #00D6C9;
}

.tabl3sec {
  background-color: #84C4FF;
}

.tabl4sec {
  background-color: #2CBFFF;
}

.recharts-tooltip-cursor {
  display: none !important;
}

.sire-table-pills.grades-table {
  gap: 10px;
}

@keyframes gradientAnimation {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.terms-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.terms-label {
  display: block;
  margin-top: 10px;
  margin: 12px 5px;
}

.terms-checkbox {
  margin-right: 5px;
  height: 18px;
  width: 20px;
}

.chart-btn-disabled {
  border-radius: 50px;
  background: #0751B5;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 17.5px 33.5px;
  position: relative;
  border: 1px solid #0751B5;
  display: inline-block;
  cursor: not-allowed;
  opacity: 0.5;
}

.dis-flile-pencil {
  display: none !important;
}


.file-upload-multiple label {
  position: absolute;
  top: 53px;
  right: 0;
  background-color: white;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}

.prfile-img-show {
  position: relative;
  width: fit-content;
}

.underline-and-bold {
  text-decoration: underline;
  color: #0178B9;
  font-weight: 600;
}



/* payment ----------------------- */

.inner-success {
  box-shadow: 0px 0px 18px -4px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding: 30px 20px 40px 20px;
  background-color: white;
  width: 100%;

}

.lotifile {
  width: 100%;
  max-width: 149px;
  margin: 0 auto;
}

.payment-success-head {
  color: #cb2222;
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
}

.payment-type-inner {
  display: flex;
}

.payment-type-inner p {
  width: 50%;
  font-size: 24px;
  font-weight: 600;
  color: black;
  margin-bottom: 7px;
}

.payment-type-inner p:last-child {
  text-align: end;
}

.payment-type-inner p.payment-discript {
  font-size: 24px;
  color: #6e6b6b;
  font-weight: 600;
}

.payment-type {
  width: 100%;
  max-width: 574px;
  margin: 0 auto;
}

.amount-paid .amount-paid-frs {
  color: #3e3e3e !important;
}

.amount-paid .amount-paid-sec {
  font-size: 30px;
  font-weight: 700;

}

.btn-success-pay {
  text-align: center;
  margin-top: 25px;
}

.upper-success-payment {
  height: 100vh;
  background-image: url(./Assets/images/girls-ride-horses\ 2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff8f8;
}

.upper-success-payment .container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.btn-reject-close:hover {
  background-color: white;
  color: #cb2222;

}

.btn-reject-close {
  border: 1px solid #cb2222;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 19px;
  font-weight: 500;
  background-color: #cb2222;
  color: white;
  transition: all 0.6s;
  cursor: pointer;
}

.btn-success-pay a {
  display: block;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}

.main-inner-cont {
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto;
  max-width: 800px;
  justify-content: center;
  padding: 20px;
}

.upper-success-payment1 {
  height: 100vh;
  background-image: url(./Assets/images/girls-ride-horses\ 2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #edfff5;
}

.payment-success-head1 {
  color: #00c27b;
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
}
.payment-failed-head1 {
  color: #cb2121;
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
}


.btn-reject1-close:hover {
  background-color: white;
  color: #00c27b;

}

.btn-reject1-close {
  border: 1px solid #00c27b;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 19px;
  font-weight: 500;
  background-color: #00c27b;
  color: white;
  transition: all 0.6s;
  cursor: pointer;
}

.main-table-home {
  width: 100%;
}

.main-table-home table {
  min-width: 500px;
}

.first-subs-th {
  width: 83px;
}

.subs-table-wrapper.main-table-home table tr th {
  font-size: 17px;
  padding: 18px 5px;
}

.inner-card-pdf {

  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 25px 14px;
  height: 100%;

}

.text-detail-horse {
  padding: 10px 0px 10px 15px;
}

.inner-card-pdf figure {

  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 93%;

}

.inner-card-pdf figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.text-detail-horse h5 {
  color: #111;
  font-family: Oswald;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
  margin: 0;
}

.inner-card-pdf:hover .text-detail-horse h5 {
  color: #015F92;
}

.inner-card-pdf:hover {
  background-color: #015f9224;
}

/* .text-detail-horse p{
  font-size: 19px;
} */
.inner-pdf-pages {
  padding-top: 100px;
  padding-bottom: 100px;
}

.title-main p {
  font-size: 26px;
  color: black;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 10px;
}

.disc-text-dpf {
  font-size: 16px;
  color: black;
  line-height: 1.6;
  margin-bottom: 0px;
  display: -webkit-box;
  /* -webkit-line-clamp: 3; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: lowercase;
}

.disc-text-dpf ::first-letter {
  text-transform: uppercase;
}

.report-title {
  text-transform: lowercase;
}

.report-title::first-letter {
  text-transform: uppercase;
}

.price-pdf-main {
  font-weight: 600;
  font-size: 20px;
  color: black;
  margin: 10px 0;
}

.buttons-bye button {
  border-radius: 50px;
  background: #0751B5;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 17.5px 33.5px;
  position: relative;
  border: 1px solid #0751B5;
  display: inline-block;
}

.buttons-bye button.view-btn {
  background-color: transparent;
  color: #0751B5;
}

.buttons-bye {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

/* Responsive */
@media (max-width: 1199px) {
  .header-logo {
    max-width: 160px;
  }

  .dollar-price {
    font-size: 40px;
  }

  .nav-heading {
    gap: 16px;
  }

  .inner-banner-main {
    background-position: right !important;
  }

}

.header-logo {
  max-width: 160px;
}

@media (max-width: 991px) {
  .header-logo {
    width: 100%;
  }

  .header-logo img {
    max-width: 100px;
  }

  .inner-bannersec {
    padding-top: 59px;
    padding-bottom: 200px;
  }

  .inner-bannersec h5 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 10px !important;
  }

  .inner-bannersec h1 {
    font-size: 70px;
    line-height: 100px;
    margin-bottom: 20px;
  }

  .inner-bannersec p {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  /* .inner-banner-main {
    background-position: right !important;
  } */
  .upper-slider-home .slick-dots ul {
    gap: 15px;
    margin-left: 0;
    padding: 20px !important;
  }

  .grades-table .form-select {
    min-width: auto;
  }

  .main-table-home table tr td {
    font-size: 11px;
  }

  .inner-pricing-plan .row .col-md-4 {
    width: 100%;
    margin-bottom: 20px;
  }

  .our-price {
    padding-top: 0;
  }

  .upper-chart-main {
    padding: 0;
  }

  .navbar-toggler.collapsed {
    background-color: #fff !important;
  }

  .buttons-bye button {
    font-size: 10px;
    padding: 10.5px 20.5px;
  }

  .inner-pdf-pages {
    padding: 50px 0;
  }

  .navbar-nav-scroll.nav-heading {
    max-height: 100% !important;
    overflow-y: visible !important;
    overflow-y: initial !important;
  }

  .inner-sgnup.custm-sign-in {
    padding: 20px;
  }

  .inner-sgnup {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .inner-banner-main {
    background-position: center !important;
  }

  .inner-banner-main {
    background-position: 100% !important;
  }

  .header-logo {
    width: 100%;
  }

  .header-logo img {
    max-width: 100px;
  }

  .inner-bannersec {
    padding-top: 59px;
    padding-bottom: 170px;
  }

  .inner-bannersec h5 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 10px !important;
  }

  .inner-bannersec h1 {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 20px;
  }

  .inner-bannersec p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  button.banner-btn.btn.btn-primary,
  .chart-btn {
    padding: 10px;
    font-size: 14px;
  }

  .upper-slider-home .slick-dots li span {
    font-size: 10px;
  }

  .upper-slider-home .slick-dots ul {
    gap: 15px;
    margin-left: 0;
    padding: 10px !important;
  }

  .grade-sec {
    padding: 50px 0;
  }

  .grade-sec h5 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 10px !important;
  }

  .charts-upper {
    padding: 20px;
  }

  .upper-chart-main {
    padding: 0;
  }

  .our-features {
    margin-left: 0;
  }

  #left-tabs-example-tabpane-s_index_1,
  #left-tabs-example-tabpane-s_index_2,
  #left-tabs-example-tabpane-s_index_3,
  #left-tabs-example-tabpane-s_index_4,
  #left-tabs-example-tabpane-home,
  #left-tabs-example-tabpane-profile,
  #left-tabs-example-tabpane-contact,
  #left-tabs-example-tabpane-index {
    overflow-x: auto;
  }

  .main-table-home table tr th {
    font-size: 12px;
  }

  .main-table-home table tr td {
    font-size: 10px;
  }

  .main-table-home table tr th {
    padding: 10px 0;
  }

  .main-table-home table {
    min-width: 600px;
  }

  .grades-table.nav.nav-pills {
    flex-wrap: wrap !important;
  }

  .grades-table .form-select {
    width: 100%;
    min-width: auto;
    margin-right: 0;
  }

  .our-price h5 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 10px !important;
    padding-bottom: 0;
  }

  .pricing-plan-sec {
    padding: 50px 0;
  }

  .our-price {
    padding: 0;
  }

  .our-price p {
    font-size: 18px;
    line-height: 26px;
  }

  .logo-foot {
    padding: 50px 0 0;
  }

  .foot-logo-main img {
    max-width: 250px;
  }

  .footer-links ul {
    gap: 20px;
    flex-wrap: wrap;
  }

  .footer-links {
    padding: 20px 0;
  }
}

@media(max-width:552px) {
  .our-features {
    padding: 16px;
  }

  .foot-logo-main {
    width: 100%;
    max-width: 157px;
    margin: 0 auto;
  }

  .inner-save-texts {
    padding: 33px 19px 23px 19px;
  }


  .save-more-head {
    font-size: 27.343px;
    line-height: 44.514px;
  }

  .save-more h5 {
    font-size: 28.343px;
    line-height: 43.514px;
    padding-bottom: 17.5px;
  }

  .inner-bannersec {
    padding-bottom: 77px;
  }

  .upper-slider-home .slick-dots {
    bottom: 0;
  }
}
/*  */
.discrip-cardpdf a {
  color: #000;
  text-decoration: underline !important;
  transition: all ease 0.6s;
}
.discrip-cardpdf a:hover{
  color: #00000080;
}
.disc-text-dpf p{
  margin: 0 ;
}
.disc-text-dpf p a{
  margin: 0 0 10px ;
}